import axios from 'axios';

import environment from '../helpers/constants/environment';
import { handleResponse } from '../helpers/common';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

const makeRequest = async (method, url, params, data, isEncrypted) => {
  const headers = {
    health_secret: environment.healthSecret,
    'Content-Type': 'text/plain',
  };

  try {
    const response = await instance({
      method,
      url,
      params,
      data,
      headers,
    });

    if (response.data.code === '1') {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    handleApiError(error, isEncrypted);
    throw error;
  }
};

const handleApiError = async (error, isEncrypted) => {
  if (axios.isCancel(error)) {
    // Request was canceled
    console.warn('Request canceled:', error.message);
  } else if (error.response) {
    const response = await handleResponse(error.response.data, isEncrypted);

    // The request was made, but the server responded with an error
    console.error('API error - Status:', error.response.status);
    console.error('Response data:', response);
    console.error('Headers:', error.response.headers);
  } else if (error.request) {
    // The request was made, but no response was received
    console.error('No response received. Request:', error.request);
  } else {
    // Something happened in setting up the request that triggered an error
    console.error('Error setting up request:', error.message);
  }
};

export default makeRequest;
