import { v4 as uuidv4 } from 'uuid';

import { getDecryptedData } from '../api/hanlders';

// Function to generate UUID
export const generateUUID = () => {
  return uuidv4();
};

export const parseQueryString = (queryString) => {
  var params = {};
  var queryStringWithoutQuestionMark = queryString.substring(1); // Remove the leading '?'
  var keyValuePairs = queryStringWithoutQuestionMark.split('&');

  for (var i = 0; i < keyValuePairs.length; i++) {
    var keyValuePair = keyValuePairs[i].split('=');
    var key = decodeURIComponent(keyValuePair[0]);
    var value =
      keyValuePair.length > 1 ? decodeURIComponent(keyValuePair[1]) : null;

    params[key] = value;
  }

  return params;
};

export const getUrlParams = () => {
  if (typeof window !== 'undefined') {
    return parseQueryString(window.location.search);
  } else {
    return {};
  }
};

export const bindQueryParams = (url, queryParams) => {
  const queryString = Object.entries(queryParams)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
  return `${url}?${queryString}`;
};

export const haveCommonElements = (arr1, arr2) => {
  const [shorterArray, longerArray] =
    arr1.length <= arr2.length ? [arr1, arr2] : [arr2, arr1];
  const set = new Set(longerArray);
  return shorterArray.some((elem) => set.has(elem));
};

export const handleResponse = async (response, isEncrypted) => {
  //   const contentType = response.headers.get("Content-Type");

  // if (contentType && contentType.indexOf('application/json') !== -1) {
  const jsonRes = (await response?.json) ? response?.json() : response;
  if (!isEncrypted) {
    return jsonRes;
  }
  const parsedResponse = await JSON.parse(getDecryptedData(jsonRes));
  //   console.log("response", response, parsedResponse);

  if (parsedResponse?.code === 1) {
    return parsedResponse;
  } else if (parsedResponse?.code === -1) {
    // DeviceEventEmitter.emit('session_expired');
    return parsedResponse;
  } else {
    return parsedResponse;
  }
  // } else {
  //     return response.text();
  // }
};
