import React from 'react';
import { useSelector } from 'react-redux';

import UserListItem from './UserListItem';

const UserList = () => {
  const { groupsList } = useSelector((s) => s.message);

  const renderUserListItem = (item, index) => {
    return <UserListItem userlistItem={item} key={item?.channel?.id} />;
  };

  return (
    <div className="w-1/4 h-screen bg-white border-r-[0.5px] border-gray-200 shadow-md">
      <h2 className="text-lg font-semibold p-4 bg-purple-700 text-center text-white">
        Chats
      </h2>
      <ul
        className="border-t border-b border-gray-100 overflow-scroll"
        style={{
          height: 'calc(100vh - 60px)',
        }}
      >
        {(groupsList ?? [])?.map(renderUserListItem)}
      </ul>
    </div>
  );
};

export default UserList;
