import { Channel, Chat as PNChat, Membership, User } from '@pubnub/chat';
import React from 'react';
import environment from '../helpers/constants/environment';
import { getUrlParams } from '../helpers/common';

const ChatContext = React.createContext({});

const ChatProvider = ({ children }) => {
  const urlParams = getUrlParams();
  const userId = urlParams?.sender;
  const userName = urlParams?.sender_name;
  const userType = urlParams?.hc_type; //hc or chief

  const [Chat, setChat] = React.useState(null);
  const [unreadCounts, setUnreadCounts] = React.useState(null);

  const initialiseChat = async () => {
    try {
      const init = await PNChat.init({
        publishKey: environment.pubnubPublishKey,
        subscribeKey: environment.pubnubSubscribeKey,
        userId,
        typingTimeout: 2000,
        storeUserActivityTimestamps: true,
        storeUserActivityInterval: 60000,
        pushNotifications: {
          // sendPushes: true,
          deviceGateway: 'gcm',
          apnsTopic: 'askTatvaChat',
        },
      });
      setChat(init);
    } catch (error) {
      console.log('[CHAT SDK INITIALISATION ERROR]: ', error);
    }
  };

  const updateUserDetails = async () => {
    try {
      const user = await Chat.getUser(userId);
      try {
        await user?.update({
          name: userName,
          type: userType,
        });
      } catch (error) {
        console.log('UpdateUserres error :::: ', error);
      }
      const unreadCounts = await Chat?.getUnreadMessagesCounts();
      setUnreadCounts(unreadCounts);
      console.log('[UNREAD COUNTS]: ', unreadCounts);
    } catch (error) {
      console.log('[USER ERROR]: ', error);
    }
  };

  React.useEffect(() => {
    initialiseChat();
  }, []);

  React.useEffect(() => {
    if (Chat) {
      updateUserDetails();
    }
  }, [Chat]);

  return (
    <ChatContext.Provider
      value={{
        Chat,
        unreadCounts,
        updateUserDetails,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

const useChat = () => {
  const context = React.useContext(ChatContext);
  if (!context) {
    throw new Error('Error!');
  }
  return context;
};

export { ChatProvider, useChat };
