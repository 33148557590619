// ChatPortal.js
import React, { useEffect } from 'react';

import UserList from '../components/chat_sdk/UserList';
import MessageWindow from '../components/chat_sdk/MessageWindow';
import { useDispatch, useSelector } from 'react-redux';
import ChatLoader from '../components/chat/ChatLoader';
import { useChat } from '../context/chat.context';
import {
  appLoadingFinished,
  onNewMessage,
  storeGroupList,
  storeHCPatientIds,
} from '../store/message/actions';
import { Helmet } from 'react-helmet';
import { getUrlParams } from '../helpers/common';
import { getPatientIdsListApi } from '../api';
import store from '../store';

const ChatPortal = () => {
  const dispatch = useDispatch();
  const { Chat, updateUserDetails } = useChat();
  const { loading: appLoading } = useSelector((s) => s.message);
  const urlParams = getUrlParams();
  const fetchGroupList = async () => {
    try {
      const res = await Chat.currentUser.getMemberships({
        limit: 100,
        sort: { updated: 'desc' },
      });
      dispatch(
        storeGroupList({
          totalCount: res?.total ?? 0,
          page: { next: res?.page?.next ?? '', prev: res?.page?.prev ?? '' },
          groupsList: res?.memberships ?? [],
        }),
      );

      //Listener for new messages
      (res?.memberships ?? []).forEach(async (membership) => {
        const {
          channel: { id },
        } = membership;
        const channel = await Chat.getChannel(id);

        channel.connect(async (message) => {
          updateUserDetails();
          // const currentMembership = res?.memberships?.find(
          //   (i) => i?.channel?.id === message?.channelId,
          // );
          // await currentMembership?.update({
          //   custom: { lastMessageTimeToken: message?.timetoken },
          // });
          // await currentMembership?.setLastReadMessageTimetoken(
          //   message?.timetoken,
          // );

          // const res1 = await Chat.currentUser.getMemberships({
          //   limit: 100,
          //   sort: { updated: 'desc' },
          // });
          // dispatch(
          //   storeGroupList({
          //     totalCount: res1?.total ?? 0,
          //     page: {
          //       next: res1?.page?.next ?? '',
          //       prev: res1?.page?.prev ?? '',
          //     },
          //     groupsList: res1?.memberships ?? [],
          //   }),
          // );

          const selectedChat = store.getState()?.message?.selectedChat;
          if (
            Notification.permission === 'granted' &&
            urlParams?.sender !== message?.userId
          ) {
            if (message?.channelId !== selectedChat?.channel?.id) {
              new Notification(`${channel?.name}`, {
                body: `${message?.content?.text ? message?.content?.text : 'Shared a file.'}`,
              });
            }
          }
          dispatch(onNewMessage({ groupId: id, message }));
        });
      });

      //Get membership updates
      // Membership.streamUpdatesOn(res?.memberships, (memberships) => {
      //   console.log('[MEMBERSHIP UPDATES]: ', memberships);
      // });
      return true;
    } catch (error) {
      console.log('[FETCH GROUPS ERROR]: ', error);
    }
  };

  const fetchHealthCoachPatientIds = async () => {
    try {
      const query_params = {
        health_coach_id: urlParams?.sender,
      };
      const { data } = await getPatientIdsListApi(query_params);
      dispatch(storeHCPatientIds(data));
      return true;
    } catch (error) {
      console.log('[FETCH PATIENT ID ERROR]: ', error);
    }
  };

  const initiateChatPortal = async () => {
    try {
      const [groupList, healthCoachPatientIds] = await Promise.all([
        fetchGroupList(),
        fetchHealthCoachPatientIds(),
      ]);
      if (groupList && healthCoachPatientIds) {
        dispatch(appLoadingFinished());
      }
    } catch (error) {
      console.log('[ERROR INITIATING CHAT PORTAL]: ', error);
    }
  };

  useEffect(() => {
    if (Chat) {
      initiateChatPortal();
    }
  }, [Chat]);

  return (
    <div className={'flex flex-row'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{urlParams?.sender_name}</title>
      </Helmet>
      {appLoading && <ChatLoader />}
      <UserList />
      <MessageWindow />
    </div>
  );
};

export default ChatPortal;
