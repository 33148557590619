import {
  SELECT_CHAT,
  APP_LOADING_FINISHED,
  STORE_GROUP_LIST,
  STORE_HISTORY,
  FETCH_HISTORY,
  NEW_MESSAGE,
  STORE_MESSAGE_SCROLL_ID,
  CLEAR_MESSAGE_SCROLL_ID,
  STORE_HC_PATIENT_IDS,
} from './constants';

const initialState = {
  groupsList: [],
  patientIds: [],
  totalCount: 0,
  page: {
    next: '',
    prev: '',
  },
  selectedChat: null,
  history: {},
  loading: true,
  messageScrollId: null,
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_GROUP_LIST:
      return { ...state, ...action.payload };
    case STORE_HC_PATIENT_IDS:
      return { ...state, patientIds: action.payload };
    case SELECT_CHAT:
      return { ...state, selectedChat: action.payload };
    case STORE_HISTORY:
      return {
        ...state,
        messageScrollId: `message-${action?.payload?.history?.messages?.[0]?.timetoken}`,
        history: {
          ...state.history,
          [action?.payload?.groupId]: action?.payload?.history,
        },
      };
    case FETCH_HISTORY:
      return {
        ...state,
        history: {
          ...state.history,
          [action?.payload?.channelId]: {
            isMore: action?.payload?.history?.isMore,
            messages: [
              ...(action?.payload?.history?.messages ?? []),
              ...(state.history?.[action?.payload?.channelId]?.messages || []),
            ],
          },
        },
      };
    case NEW_MESSAGE:
      return {
        ...state,
        history: {
          ...state.history,
          [action?.payload?.groupId]: {
            isMore: state.history?.[action?.payload?.groupId]?.isMore,
            messages: [
              ...(state.history?.[action?.payload?.groupId]?.messages || []),
              action?.payload?.message,
            ],
          },
        },
      };
    case STORE_MESSAGE_SCROLL_ID:
      return { ...state, messageScrollId: `message-${action.payload}` };
    case CLEAR_MESSAGE_SCROLL_ID:
      return { ...state, messageScrollId: null };
    case APP_LOADING_FINISHED:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default messageReducer;
