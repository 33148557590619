import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectChatAction } from '../../store/message/actions';
import { useChat } from '../../context/chat.context';
import { getUrlParams, haveCommonElements } from '../../helpers/common';

const UserListItem = ({ userlistItem }) => {
  const dispatch = useDispatch();
  const { Chat } = useChat();
  const urlParams = getUrlParams();
  const { selectedChat, patientIds } = useSelector((s) => s.message);

  const isChief = urlParams?.hc_type === 'chief';

  const [isChatEnabled, setIsChatEnabled] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(false);
  const [unreadCount, setUnreadCounts] = React.useState(null);

  const onSelectChat = (channel) => {
    dispatch(
      selectChatAction({
        channel,
      }),
    );
  };

  const checkUserMembership = async () => {
    try {
      const channel = await Chat.getChannel(userlistItem?.channel?.id);
      setCurrentChannel(channel);
      const { members } = await channel?.getMembers();
      const memberIds = members.map((m) => m?.user?.id);
      setIsChatEnabled(haveCommonElements(memberIds, patientIds));
    } catch (error) {
      console.log('[CHECK USER MEMBERSHIP ERROR]: ', error);
    }
  };
  const fetchUnreadMessagesCount = useCallback(async () => {
    if (!Chat) return;
    const unreadMessagesCounts = await Chat.getUnreadMessagesCounts();

    const currentItemChannel = unreadMessagesCounts?.find(
      (i) => i.channel?.id === userlistItem?.channel?.id,
    );
    const unreadMessageCounts = currentItemChannel?.count || 0;
    setUnreadCounts(unreadMessageCounts);
  }, []);

  const fetchUnreads = React.useCallback(() => {
    async function handleScreenFocus() {
      if (!Chat) {
        return;
      }
      await Promise.all([fetchUnreadMessagesCount()]);
    }

    handleScreenFocus();
  }, [Chat, fetchUnreadMessagesCount]);

  useEffect(() => {
    if (!currentChannel) return;
    const disconnect = currentChannel.connect((message) => {
      if (message?.userId !== urlParams?.sender) {
        fetchUnreads();
      }
    });
    return () => {
      disconnect();
    };
  }, [currentChannel]);

  useEffect(() => {
    fetchUnreads();
  }, [selectedChat]);

  useEffect(() => {
    checkUserMembership();
  }, [patientIds]);

  return (
    <li
      className={`w-full p-2 flex flex-row items-center gap-2 cursor-pointer border-b-[0.1px] border-gray-200 ${selectedChat?.channel?.id === userlistItem?.channel?.id && 'bg-purple-200'}`}
      onClick={() => onSelectChat(userlistItem?.channel)}
    >
      <div className="h-12 w-12 flex items-center justify-center bg-gray-200 rounded-full relative">
        {!isChatEnabled && !isChief && (
          <div className="w-12 h-[4px] bg-gray-500 border-[1px] border-gray-200 absolute z-10 -rotate-45" />
        )}
        {currentChannel?.type === 'direct' ? (
          <i className="fa-solid fa-user text-xl text-gray-500"></i>
        ) : (
          <i className="fa-solid fa-user-group text-xl text-gray-500"></i>
        )}
      </div>
      <div className="w-full">
        <div className="flex item-center justify-between">
          <div
            className={`text-[16px] ${isChatEnabled ? 'text-black' : 'text-gray-500'}`}
          >
            {currentChannel?.type === 'direct'
              ? currentChannel?.custom?.patientName
              : userlistItem?.channel?.name}
          </div>
          {unreadCount > 0 ? (
            <div className="w-7 h-7 rounded-full bg-green-700 flex items-center justify-center text-white">
              <p>{unreadCount}</p>
            </div>
          ) : undefined}
        </div>
        {!isChatEnabled && !isChief && (
          <div className="text-gray-500 text-[10px]">
            Not a part of this group anymore.
          </div>
        )}
      </div>
    </li>
  );
};

export default UserListItem;
