import { bindQueryParams } from '../helpers/common';
import makeRequest from './api';
import endpoints from './endpoints';

export const getPatientsListApi = (payload) => {
  return makeRequest('POST', endpoints.GET_PATIENTS_LIST, null, payload, true);
};

export const getPatientIdsListApi = (query_params) => {
  return makeRequest(
    'GET',
    bindQueryParams(endpoints.GET_PATIENTS_LIST_IDS, query_params),
    null,
    null,
    true,
  );
};
