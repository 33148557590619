import { combineReducers } from 'redux';

import patientsListReducer from './patients/reducer';
import messageReducer from './message/reducer';

const rootReducer = combineReducers({
  patientsList: patientsListReducer,
  message: messageReducer,
});

export default rootReducer;
