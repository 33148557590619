import React from 'react';
import { useSelector } from 'react-redux';
import NoSelectedChatWindow from './NoSelectedChatWindow';
import SelectedChatWindow from './SelectedChatWindow';

const MessageWindow = () => {
  const { selectedChat } = useSelector((s) => s.message);

  return (
    <div className="w-3/4 h-screen">
      {selectedChat ? <SelectedChatWindow /> : <NoSelectedChatWindow />}
    </div>
  );
};

export default MessageWindow;
