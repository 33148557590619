import React, { useEffect, useState } from 'react';

import Logo from '../../assets/icons/tatvacare-logo.png';

const ChatLoader = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-10 h-screen w-screen absolute z-50 bg-white">
      <img src={Logo} alt="logo" className="w-40 h-40 rounded-full" />
      <h3 className="text-primary text-2xl">Welcome to MyTatva Chat</h3>
      <div className="loader"></div>
    </div>
  );
};

export default ChatLoader;
