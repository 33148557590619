import moment from 'moment';

export const getDateFromTimeToken = (timetoken) => {
  const formattedDate = moment.unix(timetoken / 10000000).format();
  return formattedDate;
};

export const formatTimestamp = (timestamp, format = 'h:mm A') => {
  if (moment(timestamp, moment.ISO_8601, true).isValid()) {
    // If the timestamp is in ISO format, format it accordingly
    return moment(timestamp).format(format);
  } else {
    // If the timestamp is in Unix epoch format, convert it to milliseconds and then format it
    const timestampMilliseconds = parseInt(timestamp, 10);
    return moment(getDateFromTimeToken(timestampMilliseconds)).format(format);
  }
};

// Function to get the timestamp badge for display
export const getTimestampBadge = (timestamp) => {
  const messageDate = new Date(timestamp);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  // Format for today
  if (
    messageDate.getDate() === today.getDate() &&
    messageDate.getMonth() === today.getMonth() &&
    messageDate.getFullYear() === today.getFullYear()
  ) {
    return 'Today';
  }

  // Format for yesterday
  if (
    messageDate.getDate() === yesterday.getDate() &&
    messageDate.getMonth() === yesterday.getMonth() &&
    messageDate.getFullYear() === yesterday.getFullYear()
  ) {
    return 'Yesterday';
  }

  // Format for other dates
  return formatTimestamp(timestamp, 'DD MMM YYYY'); // Format the date as desired
};
