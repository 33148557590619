import React from 'react';
import { useSelector } from 'react-redux';
import { useChat } from '../../context/chat.context';

const ChatHeader = () => {
  const { Chat } = useChat();
  const { selectedChat } = useSelector((s) => s.message);

  const [currentChannel, setCurrentChannel] = React.useState(null);

  const fetchChannelDetails = async () => {
    try {
      const channel = await Chat.getChannel(selectedChat?.channel?.id);
      setCurrentChannel(channel);
    } catch (error) {
      console.log('[FETCH CHANNEL DETAILS ERROR]: ', error);
    }
  };

  React.useEffect(() => {
    fetchChannelDetails();
  }, [selectedChat]);

  return (
    <div className="h-[60px] w-full bg-purple-700 p-4 flex flex-row gap-2 items-center">
      <div className="h-8 w-8 flex items-center justify-center bg-gray-200 rounded-full">
        <i className="fa-solid fa-user-group text-gray-500"></i>
      </div>
      <div className="text-white cursor-default">
        {currentChannel?.type === 'direct'
          ? currentChannel?.custom?.patientName
          : currentChannel?.name}
      </div>
    </div>
  );
};

export default ChatHeader;
