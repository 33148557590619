import React, { useEffect, useRef, useState } from 'react';
import {
  generateUUID,
  getUrlParams,
  haveCommonElements,
} from '../../helpers/common';
import { useSelector } from 'react-redux';
import { useChat } from '../../context/chat.context';
import Pubnub from 'pubnub';

const ChatInput = ({ currentChannel }) => {
  const urlParams = getUrlParams();
  const fileInputRef = useRef(null);
  const { Chat } = useChat();
  const { patientIds } = useSelector((s) => s.message);

  const [input, setInput] = useState('');
  const [messageDraft, setMessageDraft] = useState(null);
  const [isChatEnabled, setIsChatEnabled] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [tempInput, setTempInput] = useState('');

  const [disableSend, setDisableSend] = useState(false);

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedDocument(file);
    } else {
      console.log('Please select a PDF file.');
    }
  };

  const handleInputChange = async (text) => {
    if (!messageDraft) {
      setInput('');
      return;
    }

    try {
      const { users, channels } = await messageDraft?.onChange(text);
      //TODO: Handle mentions for users & channels
      setInput(messageDraft?.value);
    } catch (error) {
      console.log('[MESSAGE DRAFT ONCHANGE ERROR]: ', error);
    }
  };

  const sendMessage = async () => {
    if (!messageDraft) {
      return;
    }

    if (selectedDocument && input.length <= 0) {
      alert('Are you sure you want to send the documents without any body?');
    }

    try {
      let file_array = [];
      if (selectedDocument) {
        file_array.push(selectedDocument);
        console.log('[FILE ARRAY]: ', file_array);
        messageDraft.files = file_array;
      }

      setDisableSend(true);
      const res = await messageDraft?.send({
        meta: {
          senderName: urlParams?.sender_name,
        },
      });
      // Reset Input

      messageDraft?.onChange('');
      messageDraft?.removeQuote();
      let builder = Pubnub.notificationPayload(
        urlParams?.sender_name,
        messageDraft.files?.length > 0 ? 'Sent you file' : input,
      );
      let messagePayload = builder.buildPayload(['apns', 'fcm']);
      messagePayload.text = input;

      messagePayload.pn_gcm.data = { channelID: currentChannel?.id };
      messagePayload.pn_apns.data = { channelID: currentChannel?.id };
      const res1 = await Chat.sdk.publish({
        message: messagePayload,
        channel: currentChannel?.id,
        meta: { senderName: urlParams?.sender_name },
        storeInHistory: false,
      });
      setInput('');
      setSelectedDocument(null);
      setInput('');
      setTempInput('');
      messageDraft.files = undefined;
      await messageDraft?.onChange('');
      await messageDraft?.removeQuote();
      setDisableSend(false);
    } catch (error) {
      console.log('[SEND MESSAGE ERROR]: ', error);
    }
  };

  const createMessageDraft = async () => {
    try {
      const res = await currentChannel?.createMessageDraft({
        userSuggestionSource: 'global',
        isTypingIndicatorTriggered: currentChannel?.type !== 'public',
      });
      console.log('[MESSAGE DRAFT CREATED]: ', res);
      setMessageDraft(res);
    } catch (error) {
      console.log('[MESSAGE DRAFT CREATION ERROR]: ', error);
    }
  };

  const formatBytes = (bytes, decimals = 2) =>
    bytes
      ? `${(bytes / (bytes < 1024 ? 1 : 1024 ** (bytes < 1048576 ? 1 : 2))).toFixed(decimals)} ${bytes < 1024 ? 'Bytes' : bytes < 1048576 ? 'KB' : 'MB'}`
      : '0 Bytes';

  const getCurrentMembers = async () => {
    try {
      const { members } = await currentChannel?.getMembers();
      const memberIds = members.map((m) => m?.user?.id);
      setIsChatEnabled(haveCommonElements(memberIds, patientIds));
    } catch (error) {
      console.log('[GET MEMBERS ERROR]: ', error);
    }
  };

  useEffect(() => {
    if (currentChannel) {
      createMessageDraft();
      getCurrentMembers();
    }
  }, [currentChannel]);

  return (
    <>
      {selectedDocument && (
        <div className="w-full bg-gray-100 flex flex-row gap-2">
          <div className="w-full bg-gray-100 p-2">
            <div className="flex border-dashed border-gray-300 border-2 rounded-md items-center justify-center bg-gray-50 flex-col gap-4 py-2">
              {selectedDocument?.type === 'application/pdf' ? (
                <>
                  <i className="fa-regular fa-file text-4xl text-gray-300" />
                  <div className="text-gray-300 text-md text-center">
                    {selectedDocument?.name}
                    <br />
                    {formatBytes(selectedDocument?.size ?? 0, 2)}
                  </div>
                </>
              ) : (
                <img
                  src={URL.createObjectURL(selectedDocument)}
                  className="max-h-[124px] w-auto"
                />
              )}
            </div>
          </div>
          <div onClick={() => setSelectedDocument(null)}>
            <i className="fa-regular fa-circle-xmark text-2xl text-gray-300 m-2 cursor-pointer"></i>
          </div>
        </div>
      )}
      {isChatEnabled ? (
        <div className="w-full bg-gray-100 flex flex-row gap-2">
          <div className="disabled:cursor-not-allowed bg-white h-[40px] w-[40px] rounded-full shadow-md flex cursor-pointer items-center justify-center ml-2 my-2">
            <input
              type="file"
              accept="image/jpeg, image/png, application/pdf"
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={handleFileSelect}
              style={{ display: 'none' }} // hide the file input
              ref={fileInputRef}
              id="fileInput"
            />
            <button onClick={handleButtonClick} style={{ cursor: 'pointer' }}>
              <i className="fa fa-paperclip" aria-hidden="true"></i>
            </button>
          </div>
          <textarea
            id="chat-input"
            name="chat-input"
            type="text"
            className="flex flex-1 w-full bg-white shadow-md max-h-[100px] min-h-[40px] resize-none px-[20px] py-2 my-2"
            placeholder="Type a message..."
            value={tempInput}
            onChange={(e) => {
              setTempInput(e.target.value);
            }}
            onBlur={() => {
              handleInputChange(tempInput);
            }}
            style={{
              height: '42px',
              borderRadius: '20px',
            }}
          />
          <button
            className=" disabled:cursor-not-allowed bg-white h-[40px] w-[40px] rounded-full shadow-md flex cursor-pointer items-center justify-center mr-2 my-2"
            type="submit"
            disabled={
              (!selectedDocument && input.trim().length <= 0) || disableSend
            }
            onClick={sendMessage}
          >
            {disableSend ? (
              <div className="loader" style={{ width: '32px' }} />
            ) : (
              <i className="fa-solid fa-paper-plane"></i>
            )}
          </button>
        </div>
      ) : (
        <div className="w-full h-[42px] bg-gray-100 flex items-center justify-center">
          <div className="text-[10px]">
            You cannot chat because you are not assigned to this patient
            anymore.
          </div>
        </div>
      )}
    </>
  );
};

export default ChatInput;
