import CRYPTO from 'crypto-js';

export const getDecryptedData = (cipher) => {
  var truncHexKey = CRYPTO.SHA256('9Ddyaf6rfywpiTvTiax2iq6ykKpaxgJ6')
    .toString()
    .substr(0, 32); // hex encode and truncate

  var key = CRYPTO.enc.Utf8.parse(truncHexKey);

  var iv = CRYPTO.enc.Utf8.parse('9Ddyaf6rfywpiTvT');

  var decryptedData = CRYPTO.AES.decrypt(cipher, key, {
    iv: iv,
    mode: CRYPTO.mode.CBC,
  });

  return decryptedData.toString(CRYPTO.enc.Utf8);
};
export const getEncryptedText = (data) => {
  var truncHexKey = CRYPTO.SHA256('9Ddyaf6rfywpiTvTiax2iq6ykKpaxgJ6')
    .toString()
    .substr(0, 32); // hex encode and truncate

  var key = CRYPTO.enc.Utf8.parse(truncHexKey);

  var iv = CRYPTO.enc.Utf8.parse('9Ddyaf6rfywpiTvT');

  var ciphertext = CRYPTO.AES.encrypt(JSON.stringify(data), key, {
    iv: iv,
    mode: CRYPTO.mode.CBC,
  });

  return ciphertext.toString();
};
