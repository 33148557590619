import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useChat } from '../../context/chat.context';
import { fetchHistory, storeMessageId } from '../../store/message/actions';

import MessageBubble from './MessageBubble';
import { getUrlParams } from '../../helpers/common';

const MessageList = () => {
  const { Chat } = useChat();
  const urlParams = getUrlParams();
  const dispatch = useDispatch();
  const chatMessageContainerRef = useRef(null);
  const messageScrollRef = useRef(null);
  const { selectedChat, history, messageScrollId } = useSelector(
    (s) => s.message,
  );

  const [showTypingIndicator, setShowTypingIndicator] = useState(false);

  const getTypingState = async () => {
    try {
      const currentChannel = await Chat.getChannel(selectedChat?.channel?.id);
      await currentChannel?.getTyping((value) => {
        if ((value ?? []).filter((i) => i !== urlParams?.sender).length > 0) {
          setShowTypingIndicator(true);
        } else {
          setShowTypingIndicator(false);
        }
      });
    } catch (error) {
      console.log('[GET TYPING STATE ERROR]: ', error);
    }
  };

  const renderMessages = (item, index) => {
    return (
      <MessageBubble key={index.toString()} message={item} index={index} />
    );
  };

  const handleScroll = (e) => {
    if (Chat) {
      if (e.currentTarget.scrollTop === 0) {
        if (history?.[selectedChat?.channel?.id]?.isMore) {
          // Only fetch history if there are more messages available
          getHistory();
        }
      }
    }
  };

  const handleHistoryScroll = () => {
    setTimeout(() => {
      const element = document.getElementById(messageScrollId);
      const top = element?.offsetTop;
      chatMessageContainerRef.current.scrollTop = top;
      dispatch(
        storeMessageId(
          history?.[selectedChat?.channel?.id]?.messages?.[0]?.timetoken,
        ),
      );
    });
  };

  const getHistory = async () => {
    try {
      const channel = await Chat.getChannel(selectedChat?.channel?.id);
      const his = await channel?.getHistory({
        startTimetoken:
          history?.[selectedChat?.channel?.id]?.messages?.[0]?.timetoken,
      });
      dispatch(
        fetchHistory({ history: his, channelId: selectedChat?.channel?.id }),
      );
      handleHistoryScroll();
    } catch (error) {
      console.log('[FETCH HISTORY ERROR]: ', error);
    }
  };

  useEffect(() => {
    messageScrollRef?.current?.scrollIntoView();
  }, [history]);

  useEffect(() => {
    getTypingState();
  }, []);

  return (
    <div
      ref={chatMessageContainerRef}
      className="w-full h-full bg-purple-100 overflow-scroll"
      onScroll={handleScroll}
    >
      {history?.[selectedChat?.channel?.id]?.messages?.map(renderMessages)}
      <div ref={messageScrollRef} />
      {showTypingIndicator && (
        <div className="flex max-w-[64px] items-center inline-block m-2 p-4 gap-1 bg-purple-300 rounded-r-lg rounded-tl-lg">
          <div className="bg-white p-1 rounded-full animate-pulse"></div>
          <div className="bg-white p-1 rounded-full animate-pulse"></div>
          <div className="bg-white p-1 rounded-full animate-pulse"></div>
        </div>
      )}
    </div>
  );
};

export default MessageList;
