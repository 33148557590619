import React from 'react';

const NoSelectedChatWindow = () => {
  return (
    <div className="flex h-screen bg-purple-100 justify-center items-center">
      <div className="text-center text-base font-semibold">
        Choose a Conversation and <br />
        Begin chatting...
      </div>
    </div>
  );
};

export default NoSelectedChatWindow;
