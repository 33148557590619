import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useChat } from '../../context/chat.context';
import { storeHistory } from '../../store/message/actions';

import ChatHeader from './ChatHeader';
import ChatInput from './ChatInput';
import MessageList from './MessageList';
import { getUrlParams } from '../../helpers/common';

const SelectedChatWindow = () => {
  const dispatch = useDispatch();
  const { Chat } = useChat();
  const urlParams = getUrlParams();
  const userType = urlParams?.hc_type; //hc or chief
  const userId = urlParams?.sender;
  const { selectedChat } = useSelector((s) => s.message);

  const [currentChannel, setCurrentChannel] = useState(null);

  const fetchDetails = async () => {
    try {
      const res = await Chat.getChannel(selectedChat?.channel?.id);
      setCurrentChannel(res);
    } catch (error) {
      console.log('[FETCH GROUP DETAILS ERROR]: ', error);
    }
  };

  useEffect(() => {
    (async () => {
      if (!currentChannel) return;
      const { members } = await currentChannel.getMembers();
      const currentChannelMembership = members.find(
        (m) => m?.user?.id === userId,
      );

      if (!currentChannelMembership || !currentChannel) return;
      manageUnreadCountWhileSwitchChannel();
      const disconnect = currentChannel.connect((message) => {
        currentChannelMembership.setLastReadMessageTimetoken(message.timetoken);
      });
      return () => {
        disconnect();
      };
    })();
  }, [currentChannel]);

  const manageUnreadCountWhileSwitchChannel = async () => {
    const historicalMessagesObject = await currentChannel.getHistory({
      count: 10,
    });
    if (historicalMessagesObject.messages.length) {
      dispatch(
        storeHistory({
          groupId: currentChannel?.id,
          history: historicalMessagesObject,
        }),
      );
    }

    try {
      const user = await Chat?.getUser(urlParams?.sender);
      const { memberships } = await user.getMemberships();

      const membershipToUpdate = memberships?.find(
        (m) => m?.channel?.id === currentChannel?.id,
      );

      await membershipToUpdate?.setLastReadMessageTimetoken(
        historicalMessagesObject.messages[
          historicalMessagesObject.messages.length - 1
        ].timetoken,
      );
    } catch (error) {
      console.log('error os setLastReadMessageTimetoken ::: ', error);
    }
  };

  useEffect(() => {
    if (Chat) {
      fetchDetails();
    }
  }, [Chat, selectedChat]);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <ChatHeader />
      <MessageList />
      {userType === 'hc' && <ChatInput currentChannel={currentChannel} />}
    </div>
  );
};

export default SelectedChatWindow;
